<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <el-tabs type="border-card">
        <el-tab-pane :label="$t('操作日志')">
          <div>
            <div class="system-user-search mb10">
              <div style="display: flex;width:100%;">
                <div>
                  <el-form size="small" label-width="100px" @keydown.enter="inquireClick">
                    <el-row :gutter="35">
                      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                        <el-form-item size="small" :label="$t('操作对象')">
                          <el-select v-model="handleObj" filterable>
                            <el-option :label="$t('全部')" value=""></el-option>
                            <el-option :label="$t('系统')" value="system"></el-option>
                            <el-option :label="$t('分机')" value="station"></el-option>
                            <el-option :label="$t('中继')" value="trunk"></el-option>
                            <el-option :label="$t('中继组')" value="trunkGroup"></el-option>
                            <el-option :label="$t('呼入路由')" value="routeIn"></el-option>
                            <el-option :label="$t('呼出路由')" value="routeOut"></el-option>
                            <el-option :label="$t('时间条件')" value="timecondition"></el-option>
                            <el-option :label="$t('假期')" value="day"></el-option>
                            <el-option :label="$t('总机')" value="ivr"></el-option>
                            <el-option :label="$t('分组')" value="group"></el-option>
                            <el-option :label="$t('队列')" value="queue"></el-option>
                            <el-option :label="$t('功能码')" value="funcode"></el-option>
                            <el-option :label="$t('电话会议')" value="conference"></el-option>
                            <el-option :label="$t('联系人')" value="collect"></el-option>
                            <el-option :label="$t('会议2')" value="conferenceManage"></el-option>
                            <el-option :label="$t('媒体')" value="media"></el-option>
                            <el-option :label="$t('部门')" value="department"></el-option>
                            <el-option :label="$t('黑名单')" value="blacklist"></el-option>
                            <el-option :label="$t('安全组')" value="safeGroup"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                        <el-form-item :label="$t('操作类型')">
                          <el-select v-model="handleType" filterable>
                            <el-option :label="$t('全部')" value=""></el-option>
                            <el-option :label="$t('新增')" value="add"></el-option>
                            <el-option :label="$t('编辑')" value="update"></el-option>
                            <el-option :label="$t('删除')" value="delete"></el-option>
                            <el-option :label="$t('其它')" value="other"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                        <el-form-item :label="$t('操作时间')">
                          <div class="block" style="padding:0;">
                            <el-date-picker :shortcuts="shortcuts" v-model="value1" type="datetimerange"
                                            range-separator="-"
                                            :start-placeholder="$t('开始时间')" :end-placeholder="$t('结束时间')"
                                            :editable="false" :clearable="false"
                                            format="YYYY-MM-DD HH:mm"
                                            value-format="YYYY-MM-DD HH:mm" @calendar-change="calendar"
                                            :disabled-date="disableddate"
                                            @change="changeOncick" style="width:100%">
                            </el-date-picker>
                          </div>
                        </el-form-item>
                      </el-col>
                      <div>
                        <el-button size="small" circle icon="el-icon-refresh-left" @click="resetForm"></el-button>
                        <el-button size="small" type="primary" @click="inquireClick">
                          {{ $t('查询') }}
                        </el-button>
                      </div>
                    </el-row>
                  </el-form>
                </div>
              </div>
            </div>
            <el-table :data="tableData" stripe style="width: 100%" class="table" id="out-table"
                      @sort-change="changeTableSort">
              <el-table-column prop="operation_obj" :label="$t('对象')" show-overflow-tooltip>
                <template #default="scope">
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'system'">{{ $t('系统') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'station'">{{ $t('分机') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'trunk'">{{ $t('中继') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'trunkGroup'">{{ $t('中继组') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'routeIn'">{{ $t('呼入路由') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'routeOut'">{{ $t('呼出路由') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'timecondition'">{{ $t('时间条件') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'day'">{{ $t('假期') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'ivr'">{{ $t('总机') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'group'">{{ $t('分组') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'queue'">{{ $t('队列') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'funcode'">{{ $t('功能码') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'media'">{{ $t('媒体') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'conference'">{{ $t('电话会议') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'department'">{{ $t('部门') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'blacklist'">{{ $t('黑名单') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'safeGroup'">{{ $t('安全组') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'conferenceManage'">{{ $t('会议2') }}</el-tag>
                  <el-tag size="medium" v-if="scope.row.operation_obj == 'collect'">{{ $t('联系人') }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="remark" :label="$t('操作')" show-overflow-tooltip></el-table-column>
              <el-table-column prop="data" :label="$t('内容')" show-overflow-tooltip></el-table-column>
              <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                               sortable="custom" width="180"></el-table-column>
            </el-table>
            <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                           @current-change="onHandleCurrentChange" class="mt15" :pager-count="5"
                           :page-count="log.pageCount"
                           :current-page="log.pageNum" background :page-size="log.pageSize"
                           layout="total, prev, pager, next, jumper"
                           :total="log.total">
            </el-pagination>
            <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                           :page-size="log.pageSize" small layout=" prev, pager, next" :page-count="log.pageCount"
                           :current-page="log.pageNum" :total="log.total" class="mt15"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('服务日志')">
          <div>
            <div class="system-user-search mb10">
              <el-form size="small" label-width="100px" @keydown.enter="searchServer">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('服务类型')">
                      <el-select v-model="serverType" filterable>
                        <el-option :label="$t('全部')" value=""></el-option>
                        <el-option label="FreeSWITCH" value="3"></el-option>
                        <el-option label="Web" value="1"></el-option>
                        <el-option label="Core" value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <div>
                    <el-button size="small" circle icon="el-icon-refresh-left" @click="resetServer"></el-button>
                    <el-button size="small" type="info" class="inquire-btn" @click="serverDelMore">
                      {{ $t('删除') }}
                    </el-button>
                    <el-button size="small" type="primary" class="inquire-btn" @click="searchServer">
                      {{ $t('查询') }}
                    </el-button>
                  </div>
                </el-row>
              </el-form>
            </div>
            <el-table :data="serverData" stripe style="width: 100%" class="table" id="out-table"
                      @selection-change="selectionChange" @sort-change="changeServerSort">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="name" :label="$t('文件名称')" show-overflow-tooltip></el-table-column>
              <el-table-column prop="size" :label="$t('文件大小')" show-overflow-tooltip width="90"></el-table-column>
              <el-table-column prop="type" :label="$t('服务类型')" show-overflow-tooltip width="100">
              </el-table-column>
              <el-table-column prop="update_time" :label="$t('更新时间')" show-overflow-tooltip
                               sortable="custom"></el-table-column>
              <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
                <template #default="scope">
                  <el-button size="mini" type="text" @click="serverDownOne(scope.row)">{{ $t('下载') }}</el-button>
                  <el-button size="mini" type="text" @click="serverDelOne(scope.row)"
                             class="u-delete" style="margin-left: 0px !important;">{{ $t('删除') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleServerSizeChange"
                           @current-change="onHandleServerCurrentChange" class="mt15" :pager-count="5"
                           :page-count="server.pageCount"
                           :current-page="server.pageNum" background :page-size="server.pageSize"
                           layout="total, prev, pager, next, jumper"
                           :total="server.total">
            </el-pagination>
            <el-pagination v-else @size-change="onHandleServerSizeChange" @current-change="onHandleServerCurrentChange"
                           :page-size="server.pageSize" small layout=" prev, pager, next" :page-count="server.pageCount"
                           :current-page="server.pageNum" :total="server.total" class="mt15"></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import {
  toRefs,
  reactive,
  onMounted,
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {
  getSysLog,
  getServerLog,
  delServerLog,
  delServerLogMore
} from '@/http/sysLog.api.js';
import moment from "moment/moment";

export default {
  name: 'sysLog',
  components: {},
  setup() {
    const {t}        = useI18n()
    const state      = reactive({
      sysTitle: '',
      handleObj: '',
      handleType: '',
      serverType: '',
      tableData: [],
      serverData: [],
      selectionList: [],
      log: {
        pageSize: 5, //每页数据条数
        pageNum: 1, //页码
        total: 0, //总数据条数
        pageCount: 0, //总页数
      },
      server: {
        pageSize: 5, //每页数据条数
        pageNum: 1, //页码
        total: 0, //总数据条数
        pageCount: 0, //总页数
      },
      clientWidth: '',
      sortType: '',
      serverSortType: '',
      sortBy: '',
      serverSortBy: '',
      shortcuts: [{
        text: t('当天'),
        value: () => {
          const end   = moment().format('YYYY-MM-DD HH:mm:ss')
          const start = moment(new Date().getTime()).format('YYYY-MM-DD') + ' ' + '00:00:00'
          return [start, end]
        },
      },
        {
          text: t('昨天'),
          value: () => {
            const end   = moment(new Date().getTime() - 3600 * 24 * 1000).format('YYYY-MM-DD') + ' ' +
                '23:59:59'
            const start = moment(new Date().getTime() - 3600 * 24 * 1000).format('YYYY-MM-DD') + ' ' +
                '00:00:00'
            return [start, end]
          },
        },
        {
          text: t('本周'),
          value: () => {
            const end   = moment().endOf('isoweek').format('YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('isoweek').format('YYYY-MM-DD HH:mm:ss')
            return [start, end]
          },
        },
        {
          text: t('上周'),
          value: () => {
            const end   = moment().endOf('isoweek').subtract('week', 1).format('YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('isoweek').subtract('week', 1).format('YYYY-MM-DD HH:mm:ss')
            return [start, end]
          },
        },
        {
          text: t('本月'),
          value: () => {
            const end   = moment().endOf('month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
            return [start, end]
          },
        },
        {
          text: t('上月'),
          value: () => {
            const end   = moment().endOf('month').subtract('month', 1).endOf('month').format(
                'YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('month').subtract('month', 1).format('YYYY-MM-DD HH:mm:ss')
            return [start, end]
          },
        },
      ],
      value1: '',
      value2: '',
    });
    const blackList  = (state) => {
      let params = {
        start_time: state.value1[0],
        end_time: state.value1[1],
        type: state.handleType,
        obj: state.handleObj,
        page: state.log.pageNum,
        sort_type: state.sortType,
        sort_by: state.sortBy
      }
      getSysLog(params).then(res => {
        if (res.code == 200) {
          state.tableData     = res.data.list;
          state.log.pageCount = res.data.pages;
          state.log.pageSize  = res.data.length;
          state.log.total     = res.data.rows;
          state.sysTitle      = res.tag;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    const serverList = (state) => {
      let params = {
        start_time: state.value1[0],
        end_time: state.value1[1],
        type: state.serverType,
        page: state.server.pageNum,
        sort_type: state.serverSortType,
        sort_by: state.serverSortBy
      }
      getServerLog(params).then(res => {
        if (res.code == 200) {
          state.serverData       = res.data.list;
          state.server.pageCount = res.data.pages;
          state.server.pageSize  = res.data.length;
          state.server.total     = res.data.rows;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    const print      = () => {
      blackList(state);
      serverList(state);
    }
    onMounted(() => {
      let newDate   = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/,
          '');
      let strDate   = new Date(new Date(new Date().toLocaleDateString()).getTime());
      var startTime = strDate.getFullYear() + "-" + ((strDate.getMonth() + 1) < 10 ? "0" + (strDate.getMonth() +
          1) : (strDate.getMonth() + 1)) + "-" + (strDate.getDate() < 10 ? "0" + strDate.getDate() : strDate.getDate()) + " " + (strDate.getHours() < 10 ? "0" + strDate.getHours() : strDate.getHours()) + ":" + (
          strDate.getMinutes() < 10 ? "0" + strDate.getMinutes() : strDate.getMinutes()) + ":" + (strDate.getSeconds() < 10 ? "0" + strDate.getSeconds() : strDate.getSeconds());
      let arr       = [];
      arr.push(startTime)
      arr.push(newDate)
      state.value1 = arr;

      blackList(state);
      serverList(state);
      state.clientWidth = window.innerWidth;
    })

    function inquireClick() {
      blackList(state);
    }

    function searchServer() {
      serverList(state);
    }

    const selectionChange             = (list) => {
      let array           = list.map(item => {
        return {name: item.name, type: item.type};
      })
      state.selectionList = array;
    }
    const changeTableSort             = (column) => {
      state.sortBy   = column.prop;
      state.sortType = column.order;
      blackList(state)
    }
    const changeServerSort            = (column) => {
      state.serverSortBy   = column.prop;
      state.serverSortType = column.order;
      serverList(state)
    }
    // 分页改变
    const onHandleSizeChange          = (val) => {
      state.log.pageSize = val;
      blackList(state)
    };
    // 分页改变
    const onHandleServerSizeChange    = (val) => {
      state.server.pageSize = val;
      serverList(state)
    };
    // 分页改变
    const onHandleCurrentChange       = (val) => {
      state.log.pageNum = val;
      blackList(state)
    };
    const onHandleServerCurrentChange = (val) => {
      state.server.pageNum = val;
      serverList(state)
    };

    //时间范围选择
    function disableddate(time) {
      const nowDate = new Date();
      if (state.value2) {
        let isSelected = false;
        const startDay = (new Date(state.value2).getDate() - 1) * 24 * 3600 * 1000;
        const endDay   = (new Date(
            new Date(state.value2).getFullYear(),
            new Date(state.value2).getMonth() + 1,
            0
        ).getDate() - new Date(state.value2).getDate()) * 24 * 3600 * 1000;
        let minTime    = state.value2 - startDay;
        let Timer      = minTime + startDay;
        let maxTime    = Timer + endDay;
        return isSelected || time.getTime() > nowDate || time.getTime() > maxTime
      } else {
        return time.getTime() > nowDate;
      }
    }

    function serverDownOne(param) {
      const a         = document.createElement("a");
      a.href          = param.api;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    function serverDelOne(param) {
      ElMessageBox.confirm(t("你确定要删除该文件吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              name: param.name,
              type: param.type
            }
            delServerLog(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                serverList(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    }

    //批量删除
    function serverDelMore() {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的文件"),
          type: "warning",
          duration: 2000,
        });
        return;
      } else {
        ElMessageBox.confirm(t("你确定要删除该文件吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                objs: state.selectionList
              }
              delServerLogMore(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  serverList(state)
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }

    //获取开始时间并赋值给value2
    function calendar(arr) {
      state.value2 = arr[0];
    }

    //确认取消value2值
    function changeOncick() {
      state.value2 = '';
    }

    const resetForm = () => {
      state.handleObj  = "";
      state.handleType = "";

      let newDate   = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/,
          '');
      let strDate   = new Date(new Date(new Date().toLocaleDateString()).getTime());
      var startTime = strDate.getFullYear() + "-" + ((strDate.getMonth() + 1) < 10 ? "0" + (strDate.getMonth() +
          1) : (strDate.getMonth() + 1)) + "-" + (strDate.getDate() < 10 ? "0" + strDate.getDate() : strDate.getDate()) + " " + (strDate.getHours() < 10 ? "0" + strDate.getHours() : strDate.getHours()) + ":" + (
          strDate.getMinutes() < 10 ? "0" + strDate.getMinutes() : strDate.getMinutes()) + ":" + (strDate.getSeconds() < 10 ? "0" + strDate.getSeconds() : strDate.getSeconds());
      let arr       = [];
      arr.push(startTime)
      arr.push(newDate)
      state.value1 = arr;
    }

    const resetServer = () => {
      state.serverType = "";
    }

    return {
      ...toRefs(state),
      changeTableSort,
      changeServerSort,
      inquireClick,
      searchServer,
      onHandleSizeChange,
      onHandleServerSizeChange,
      onHandleCurrentChange,
      onHandleServerCurrentChange,
      selectionChange,
      print,
      disableddate,
      serverDownOne,
      serverDelOne,
      serverDelMore,
      calendar,
      changeOncick,
      resetForm,
      resetServer
    }
  }
}
</script>
<style lang="scss">
.el-form-item__label {
  padding: 0 12px 0 0 !important;
}
</style>
