import request from './index.js'

//获取操作日志列表
export function getSysLog(data = {}) {
    return request({
        url: "/api/system/log/search",
        method: "GET",
        params: data
    })
}

//获取服务日志列表
export function getServerLog(data = {}) {
    return request({
        url: "/api/system/server/search",
        method: "GET",
        params: data
    })
}

//删除服务日志文件
export function delServerLog(data={}) {
    return request({
        url: "/api/system/server/delete",
        method: "DELETE",
        data
    })
}

//批量删除服务日志文件
export function delServerLogMore(data={}) {
    return request({
        url: "/api/system/server/batchdel",
        method: "DELETE",
        data
    })
}
